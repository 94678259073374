import React from 'react';
import styled from 'styled-components';

const StyledCompanyName = styled('svg')`
  margin-top: 5px;
  width: 179px;
  ${({ theme }) => theme.mq.md} {
    width: 279px;
    margin-top: 42px;
  }
`;

const CompanyName = () => (
  <StyledCompanyName
    width="279"
    height="34"
    viewBox="0 0 279 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0171 3.79978H0V1.05444H29.0715V3.79978H16.0544V33.7874H13.0171V3.79978Z"
      fill="#FF8332"
    />
    <path
      d="M62.4824 1.26648H88.9505V4.01182H65.5198V14.782H82.8759V17.3162H65.5198V31.0429H88.9505V33.7882H62.4824V1.26648Z"
      fill="#FF8332"
    />
    <path
      d="M137.547 0L156.205 34H152.951L148.395 25.764H126.266L121.71 34H118.239L137.547 0ZM147.093 23.2298L137.547 5.49068L127.785 23.2298H147.093Z"
      fill="#FF8332"
    />
    <path
      d="M188.314 1.26648H191.352V31.2541H212.613V33.9994H188.314V1.26648Z"
      fill="#FF8332"
    />
    <path
      d="M260.125 0L279 34H275.745L271.189 25.764H249.06L244.504 34H241.033L260.125 0ZM269.888 23.2298L260.342 5.49068L250.579 23.2298H269.888Z"
      fill="#FF8332"
    />
  </StyledCompanyName>
);

export default CompanyName;
