import React from 'react';
import styled from 'styled-components';

const StyledEclipse = styled('svg')`
  width: 180px;
  ${({ theme }) => theme.mq.md} {
    width: 230px;
  }
`;

const Eclipse = () => (
  <StyledEclipse
    width="218"
    height="218"
    viewBox="0 0 218 218"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.927 213.486V70.4858H106.789H54.2925V66.7763H162.464V70.4858H110.243V213.498C169.869 212.845 218 165.304 218 106.752C218 47.7946 169.199 0 109 0C48.801 0 0 47.7946 0 106.752C0 165.032 47.6849 212.404 106.927 213.486Z"
      fill="#FF8332"
    />
    <path d="M169.786 66.7762H165.918V70.6232H169.786V66.7762Z" fill="white" />
    <path d="M177.108 66.635H173.24V70.4819H177.108V66.635Z" fill="white" />
  </StyledEclipse>
);

export default Eclipse;
