import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const StyledImg = styled('img')`
  width: 130px;
  height: 130px;
  ${({ theme }) => theme.mq.smm} {
    width: 180px;
    height: 180px;
  }
  ${({ theme }) => theme.mq.md} {
    width: 230px;
    height: 230px;
  }
`;

export const StyledSpinner = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: 3s ${rotate} forwards infinite linear;
`;
