import styled from 'styled-components';

export const StyledSuccessWrapper = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const StyledSuccesImgwrapper = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledBubbleSmall = styled('div')`
  width: 11px;
  height: 11px;
  background-color: ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -30px;
  opacity: 0.25;
  ${({ theme }) => theme.mq.md} {
    left: -10px;
  }
`;

export const StyledBubbleMedium = styled('div')`
  width: 17px;
  height: 17px;
  background-color: ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  position: absolute;
  right: -30px;
  top: 20%;
  transform: translateY(-50%);
  opacity: 0.25;
`;

export const StyledBubbleBig = styled('div')`
  width: 27px;
  height: 27px;
  background-color: ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  position: absolute;
  top: -10%;
  left: -10%;
  opacity: 0.25;
`;

export const StyledSuccesText = styled('span')`
  display: block;
  margin-top: 30px;
  text-align: center;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.2rem;
  padding: 0 30px;
  max-width: 560px;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mq.md} {
    margin-top: 5.4rem;
    font-size: 2.4rem;
    line-height: 4.2rem;
  }
`;
