import React from 'react';
import PropTypes from 'prop-types';
import { IconCheck } from 'components/atoms/icons';
import {
  StyledInputWrapper,
  StyledLabel,
  StyledCheckbox,
  StyledCheckmark,
  StyledLabelContent,
  StyledError,
} from './StyledCheckbox';

const Checkbox = ({
  children,
  onChange,
  onBlur,
  name,
  value,
  errorMessage,
}) => (
  <StyledInputWrapper>
    <StyledLabel htmlFor={name}>
      <StyledCheckbox
        name={name}
        value={value}
        type="checkbox"
        id={name}
        onChange={e => onChange(e)}
        onBlur={e => onBlur(e)}
      />
      <StyledCheckmark checked={value}>
        {value && <IconCheck />}
      </StyledCheckmark>

      <StyledLabelContent>{children}</StyledLabelContent>
      {errorMessage && <StyledError> {errorMessage}</StyledError>}
    </StyledLabel>
  </StyledInputWrapper>
);

Checkbox.propTypes = {
  errorMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
  errorMessage: '',
};

export default Checkbox;
